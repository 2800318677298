import React from 'react';
import styled from 'styled-components';


const Tab = styled.div`
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border: 1px solid;
  border-color: ${props => props.isActive ? 'black' : 'transparent'};
  box-shadow: ${props => props.isActive ? '0 2px 8px 0 rgba(0,0,0,.2)' : 'none'};;
  border-radius: 8px;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;

const CountryTabContainer = styled.div`
  display: block;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;

const renderCountries = ({ selectedIndex, onChange, countries }) => {
  const countryDivs = countries.map((country, idx) => <Tab
    isActive={idx === selectedIndex}
    key={country + idx}
    onClick={() => onChange(idx)}
  >{country}</Tab>)
  return <CountryTabContainer>{countryDivs}</CountryTabContainer>
}

export default renderCountries