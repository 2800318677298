import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// import usaimage from '../../images/logos/countries/usa.png';
// <img src={usaimage} alt="Logo" />

import { Section, Container } from '@components/global';
import CountrySelector from '@components/common/Generic/CountrySelector';
import { FEED, DEFAULT_FEED_IMG } from '@data/newsfeed.js'

const LABEL_WILDCARD = 'All';

const COUNTRIES = [
  LABEL_WILDCARD,
  'USA',
  'Canada',
  'UK',
  'Germany',
  'France',
  'Ireland',
  'Australia',
  'New Zealand',
  'Hong Kong and Singapore',
];

const NewsComponent = ({ labels, title, description, image, link }, index) => {
  return (
    <NewContainer key={link + index}>
      <div style={{textAlign: 'left'}}>
        <a href={link} style={{display: 'inline-bblock'}}>
          <ArticleImg src={image || DEFAULT_FEED_IMG} alt={title} />
        </a>
      </div>
      <Title href={link} target="_blank" title={title}>
        {title}
      </Title>
      <div style={{ marginTop: 10, marginBottom: 15 }}>
        {labels.map(label => (
          <Label key={label}>{label}</Label>
        ))}
      </div>
      <Subtitle href={link} target="_blank">
        {description}
      </Subtitle>
    </NewContainer>
  );
};

const Feed = () => {
  const [index, setIndex] = useState(0);
  return (
    <Section id="global accolades" accent="secondary">
      <div style={{ height: 60 }}></div>
      <Container style={{ position: 'relative' }}>
        <h1>Higher Education News</h1>
        <Title>Stay updated with us</Title>
        <br />
        <br />
        <br />
        <CountrySelector
          selectedIndex={index}
          onChange={tabIndex => setIndex(tabIndex)}
          countries={COUNTRIES}
        />
        <br />
        <div style={{ display: 'inline' }}>
          {FEED.filter(
            news =>
              news.labels &&
              news.labels.find(
                label =>
                  label.toLowerCase() ===
                    COUNTRIES[index].toLocaleLowerCase() ||
                  COUNTRIES[index] === LABEL_WILDCARD
              )
          ).map((news, index) => NewsComponent(news, index))}
        </div>
      </Container>
    </Section>
  );
};

const zoomout = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.95);
  }
`;

const ArticleImg = styled.img`
  width: 100%;
  &:hover {
    animation: ${zoomout} 0.5s;
    animation-fill-mode: forwards;
  }
}
`;

const Label = styled.div`
  display: inline;
  width: fit-content;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 6px;
  font-size: small;
  font-weight: bold;
  color: ${props => props.theme.color.black.onTurqoise};

  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: ${props => props.theme.color.black.onTurqoise};
  // box-shadow: 0 2px 8px 0 rgba(0,0,0,.2);
  border-radius: 16px;
`;

const Subtitle = styled.a`
  display: block;
  display: -webkit-box;
  max-width: 100%;
  align: justify;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  text-decoration: none;
  margin-top: 16px;
  color: ${props => props.theme.color.black.light};
`;

const Title = styled.a`
  display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};
`;

const NewContainer = styled.div`
  width: 43%;
  float: left;
  max-height: 390px;
  margin-left: 0px;
  margin-right: 40px;
  margin-bottom: 80px;
  border: '1px solid';

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 96%;
    margin-bottom: 140px;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 96%;
    margin-bottom: 60px;
  }
`;

export default Feed;
