import React from 'react';
import styled from 'styled-components';

const CountrySelectorContainer = styled.div`
  display: none;
  

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: block;
  }
`;

const renderCountrySelector = ({onChange, countries}) => {
  const countryDivs = countries.map((country, idx) =>
    <option key={idx + country} value={idx}>
      {country}
    </option>)
  return <CountrySelectorContainer>
    <h4>Select Country</h4>
    <select onChange={evt => onChange(evt.target.value)}>
      {countryDivs}
    </select>
  </CountrySelectorContainer>
}

export default renderCountrySelector