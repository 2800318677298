export const FEED = [
    // USA
    {
      labels: ['USA'],
      title: 'A Post-Pandemic Strategy for U.S. Higher Ed',
      description:
        'Universities have many pressing short-term issues to deal with right now: large budget cuts, a growing reluctance among students to pay full tuition fees for online education ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQCX_CiUiKX-8sCp&w=500&h=261&url=https%3A%2F%2Fhbr.org%2Fresources%2Fimages%2Farticle_assets%2F2020%2F06%2FJun20_02_155430680.jpg&cfs=1&ext=jpg&_nc_hash=AQCrxrxSqiw9dRsf',
      link: 'https://hbr.org/2020/06/a-post-pandemic-strategy-for-u-s-higher-ed',
    },
    {
      labels: ['USA'],
      title: 'Can International Higher Education Survive Covid-19?',
      description:
        'According to UNESCO there were over 5.3 million international students in 2017, up from 2 million in 2000. More than half of these were enrolled in educational programs in six countries ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQAYaGnY08GSAYSi&w=500&h=261&url=https%3A%2F%2Fthumbor.forbes.com%2Fthumbor%2Ffit-in%2F1200x0%2Ffilters%253Aformat%2528jpg%2529%2Fhttps%253A%252F%252Fspecials-images.forbesimg.com%252Fimageserve%252F5ef08bdb6171eb0006f7099e%252F0x0.jpg&cfs=1&ext=jpg&_nc_hash=AQDJwRRb-sDzuGi0',
      link:
        'https://www.forbes.com/sites/mattsymonds/2020/06/22/can-international-higher-education-survive-covid-19/#273565516a6d',
    },
    {
      labels: ['USA'],
      title: 'Trump Expands Immigration Restrictions',
      description:
        'President Trump signed an executive order Monday suspending entry to the U.S. by foreign nationals through a number of nonimmigrant work visa programs, including the H-1B ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQCyTG0OyT8QK2HF&w=500&h=261&url=https%3A%2F%2Fwww.insidehighered.com%2Fsites%2Fdefault%2Fserver_files%2Fmedia%2Fproclamation.png&cfs=1&ext=jpg&_nc_hash=AQA2xD-W_kF7GSO_',
      link:
        'https://www.insidehighered.com/news/2020/06/23/immigration-restrictions-target-h-1bs-certain-j-visas',
    },
    {
      labels: ['USA'],
      title:
        "In person, online classes or a mix: Colleges' fall 2020 coronavirus reopening plans, detailed",
      description:
        'Michigan State University President Samuel Stanley says he knows the coronavirus will spread if students come back to campus in the fall. While safety is “paramount," managing ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQCQb2vN9xhcR31J&w=500&h=261&url=https%3A%2F%2Fwww.gannett-cdn.com%2Fpresto%2F2020%2F06%2F03%2FPTAL%2Fe7dca29f-96bd-4d36-86c2-d66a4c5b9e85-FAMU_CAMPUS_PHOTO.jpg%3Fcrop%3D5079%2C2857%2Cx0%2Cy258%26width%3D3200%26height%3D1801%26format%3Dpjpg%26auto%3Dwebp&cfs=1&ext=jpg&_nc_hash=AQBkQu7D4_FmMbUD',
      link:
        'https://www.usatoday.com/story/news/education/2020/06/22/coronavirus-reopening-college-fall-2020/3210719001/',
    },
    {
      labels: ['USA'],
      title: 'International Students Face Dire Prospects In Fall',
      description:
        'Even before the coronavirus pandemic, fewer international students were choosing U.S. universities. Today, serious questions have emerged about international education ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQAC6pT-aKjAI10C&w=500&h=261&url=https%3A%2F%2Fthumbor.forbes.com%2Fthumbor%2Ffit-in%2F1200x0%2Ffilters%253Aformat%2528jpg%2529%2Fhttps%253A%252F%252Fspecials-images.forbesimg.com%252Fimageserve%252F1225140975%252F0x0.jpg%253FcropX1%253D0%2526cropX2%253D6048%2526cropY1%253D114%2526cropY2%253D3515&cfs=1&ext=jpg&_nc_hash=AQC2WscUnidxGJ35',
      link:
        'https://www.forbes.com/sites/stuartanderson/2020/06/15/international-students-face-dire-prospects-in-fall/#679844eacf76',
    },
    {
      labels: ['USA'],
      title: 'Updated Visa Guidelines International Students Should Know',
      description:
        'SINCE THE CORONAVIRUS pandemic forced school closures nationwide and a transition to online classes, enrolled international students have had numerous questions about possible ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQCnrB6BwkDw4kQU&w=500&h=261&url=https%3A%2F%2Fwww.usnews.com%2Fdims4%2FUSNEWS%2Ffd15beb%2F2147483647%2Fresize%2F1200x%253E%2Fquality%2F85%2F%3Furl%3Dhttp%253A%252F%252Fmedia.beam.usnews.com%252Fa0%252F1b%252F481d26ce46d2af9243c2b0b90b32%252F200616-stock.jpg&cfs=1&ext=jpg&_nc_hash=AQDpAFez0WcG5YAp',
      link:
        'https://www.usnews.com/education/best-colleges/articles/updated-visa-guidelines-international-students-should-know',
    },
    {
      labels: ['USA'],
      title: 'Ways U.S. Colleges Support International Students During COVID-19',
      description:
        "THE CORONAVIRUS pandemic and resulting stay-at-home orders caught much of the U.S. off guard, but for some international students far from home, it's been particularly trying ...",
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQA5WElkkwXDTauA&w=500&h=261&url=https%3A%2F%2Fwww.usnews.com%2Fdims4%2FUSNEWS%2F959b20b%2F2147483647%2Fresize%2F1200x%253E%2Fquality%2F85%2F%3Furl%3Dhttp%253A%252F%252Fmedia.beam.usnews.com%252F1c%252F3a%252F49f2f96e4f2381e2438a25fd060c%252F200609-collegestudentonlinetutoring-stock.jpg&cfs=1&ext=jpg&_nc_hash=AQBcEymvYtfoi_lv',
      link:
        'https://www.usnews.com/education/best-colleges/articles/ways-us-colleges-support-international-students-during-coronavirus?aliId=eyJpIjoiSytQMXI0NG41VWg1K0xvRyIsInQiOiI1dDNoSG1leGhiY3hXTWpSZ1wvNDlEUT09In0%253D',
    },
    {
      labels: ['USA'],
      title:
        'Lawmakers urge Trump admin to ensure international students can enroll for Fall classes',
      description:
        'A bipartisan group of 21 Congressmen has urged the Trump administration to take all necessary steps to ensure international students can enrol for Fall classes ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQBNZ3zPbFyO0BLc&w=500&h=261&url=https%3A%2F%2Fimages.indianexpress.com%2F2020%2F03%2Fstudyabroad759.jpg%3Fw%3D759&cfs=1&ext=jpg&_nc_hash=AQCiSugFRjayVH6o',
      link:
        'https://indianexpress.com/article/education/study-abroad/us-fall-classes-for-international-students-6440266/',
    },
    {
      labels: ['USA'],
      title: 'Indian Tech Graduate Students Plummeted In U.S. Before Covid-19',
      description:
        'Even before Covid-19 closed colleges and stopped most international travel, fewer graduate students from India, a key source of tech talent in America, decided to study at ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQDQz4CTpo3_IgjR&w=500&h=261&url=https%3A%2F%2Fthumbor.forbes.com%2Fthumbor%2Ffit-in%2F1200x0%2Ffilters%253Aformat%2528jpg%2529%2Fhttps%253A%252F%252Fspecials-images.forbesimg.com%252Fimageserve%252F1207083796%252F0x0.jpg&cfs=1&ext=jpg&_nc_hash=AQAdWLU_zeBNLxKR',
      link:
        'https://www.forbes.com/sites/stuartanderson/2020/06/08/indian-tech-graduate-students-plummeted-in-us-before-covid-19/#3e2905684d7f',
    },
    {
      labels: ['USA'],
      title:
        'Trump Suspends Temporary Work Visas but — for Now — Leaves Alone a Program for International Students',
      description:
        'International students won an unexpected reprieve as the Trump administration backed off plans to curtail optional practical training, the popular work program for ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQBMjEq8DxAFjF5O&w=500&h=261&url=https%3A%2F%2Fwww.chronicle.com%2Fimg%2Fphotos%2Fbiz%2Fphoto_95810_wide_large.jpg&cfs=1&ext=jpg&_nc_hash=AQA-mLo7xCvZtNIc',
      link:
        'https://www.chronicle.com/article/Trump-Suspends-Temporary-Work/249037',
    },
    {
      labels: ['USA'],
      title: 'Can International Higher Education Survive Covid-19?',
      description:
        'According to UNESCO there were over 5.3 million international students in 2017, up from 2 million in 2000. More than half of these were enrolled in educational programs in ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQAYaGnY08GSAYSi&w=500&h=261&url=https%3A%2F%2Fthumbor.forbes.com%2Fthumbor%2Ffit-in%2F1200x0%2Ffilters%253Aformat%2528jpg%2529%2Fhttps%253A%252F%252Fspecials-images.forbesimg.com%252Fimageserve%252F5ef08bdb6171eb0006f7099e%252F0x0.jpg&cfs=1&ext=jpg&_nc_hash=AQDJwRRb-sDzuGi0',
      link:
        'https://www.forbes.com/sites/mattsymonds/2020/06/22/can-international-higher-education-survive-covid-19/#7048e70c6a6d',
    },
    {
      labels: ['USA'],
      title: 'When college is online, where do international students go?',
      description:
        'Students from other countries come to the U.S. not just to study, but to take in a different culture. COVID-19 has changed the campus experience, leaving international ...',
      image:
        'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQCjqYlLmn4fgqge&w=500&h=261&url=https%3A%2F%2Fimages.csmonitor.com%2Fcsm%2F2020%2F06%2F0611-INTERNATIONALSTUDENTS.jpg%3Falias%3Dstandard_900x600&cfs=1&ext=jpg&_nc_hash=AQA2lJHPt7SIFYP_',
      link:
        'https://www.csmonitor.com/USA/Education/2020/0611/When-college-is-online-where-do-international-students-go',
    },
  
    // Canada
    {
      labels: ['Canada'],
      title: 'Amid a pandemic, 67,000 international students arrive in Canada',
      description:
        'Is Canada safe? According to 67,000 international students who arrived on its shores between January and April 2020, the answer is yes.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBRZHEPPz03m7Av&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2FDAVE-CHAN-AFP.jpg&cfs=1&ext=jpg&_nc_hash=AQCEWvoe-sS-XLVp',
      link: 'https://www.studyinternational.com/news/is-canada-safe-students/',
    },
    {
      labels: ['Canada'],
      title: 'Where are Canada’s international students arriving from?',
      description:
        'Canada welcomed 67,000 new international students between January and April 2020 from 156 different countries.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBiRNQNlLosR3Jd&w=500&h=261&url=https%3A%2F%2Fwww.cicnews.com%2Fwp-content%2Fuploads%2F2020%2F06%2FWebp.net-resizeimage-8.jpg&cfs=1&ext=jpg&_nc_hash=AQCE5CrVhjlRtz7G',
      link:
        'https://www.cicnews.com/2020/06/where-are-canadas-international-students-arriving-from-0614717.html#gs.9rcm5g',
    },
    {
      labels: ['Canada'],
      title:
        'Qualtrough says work underway to provide help for international students',
      description:
        'Ottawa says it is working on ways to support international students in Canada currently left out of existing COVID-19 emergency aid programs ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAfobgLEb24XZZP&w=500&h=261&url=https%3A%2F%2Fipolitics.ca%2Fwp-content%2Fuploads%2F2018%2F02%2FFeb.1504275-e1586533710151.jpg&cfs=1&ext=jpg&_nc_hash=AQBQLgArB41A8E9y',
      link:
        'https://ipolitics.ca/2020/06/22/qualtrough-says-work-underway-to-provide-help-for-international-students/',
    },
    {
      labels: ['Canada'],
      title:
        'What you should know: Work and study permits for international students in Canada',
      description:
        'Canada has introduced many travel restrictions and temporary changes to its immigration policy in light of COVID-19. This has affected the study permit ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBvBf3bQyqhY6ho&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2FCanada-COLE-BURSTON-GETTY-IMAGES-NORTH-AMERICA-GETTY-IMAGES-VIA-AFP.jpg&cfs=1&ext=jpg&_nc_hash=AQAfCj453s0Vbnlb',
      link: 'https://www.studyinternational.com/news/study-permit-work/',
    },
    {
      labels: ['Canada'],
      title: 'Fast-track your studies in Canada from India',
      description:
        'A step-by-step guide for Indians that want to study in Canada in fall 2020 including how to fast-track your study permit application. India is by far the leading source ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAbgIvVmIWZguZK&w=500&h=261&url=https%3A%2F%2Fwww.cicnews.com%2Fwp-content%2Fuploads%2F2020%2F05%2Fman-wearing-jacket-carrying-a-backpack-3930029-1024x683.jpg&cfs=1&ext=jpg&_nc_hash=AQAzhbgGxictNhf4',
      link:
        'https://www.cicnews.com/2020/06/fast-track-your-studies-in-canada-from-india-0614453.html#gs.9rgegc',
    },
    {
      labels: ['Canada'],
      title: 'Extend pandemic relief supports to international students',
      description:
        'The operating revenues of Canadian universities such as U of T are strongly tied to students’ enrolment.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDamuy4QNw-ToaE&w=500&h=261&url=https%3A%2F%2Fwww.hilltimes.com%2Fwp-content%2Fuploads%2F2020%2F06%2Fjoydeep-pal-EZiSDT-v8XY-unsplash-750x375.jpg&cfs=1&ext=jpg&_nc_hash=AQBj-DFHbh4hXF6e',
      link:
        'https://www.hilltimes.com/2020/06/26/extend-pandemic-relief-supports-to-international-students/254638',
    },
    {
      labels: ['Canada'],
      title: 'Pandemic may fail foreign enrolment at Canadian universities',
      description:
        'A new report from Statistics Canada warns that COVID-19 might depress the number of international students studying in Canada, a growing, important source of revenue ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?w=500&h=261&url=https%3A%2F%2Fwww.vicnews.com%2Fwp-content%2Fuploads%2F2020%2F06%2F21843463_web1_Graduation-FWM-200429-FWPS_1-1024x683.jpg&cfs=1&ext=jpg&_nc_hash=AQDp_2LBGnxw8LFn',
      link:
        'https://www.vicnews.com/news/pandemic-may-fail-foreign-enrolment-at-canadian-universities/',
    },
    {
      labels: ['Canada'],
      title: 'Canada Extends Coronavirus Travel Ban On Most Foreign Travelers',
      description:
        'Canada announced yesterday (June 30, 2020) that it will be extending its ban on foreign travelers until at least July 31, 2020. The ban applies to all foreign nationals ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCXJzxMzmvVgvmk&w=500&h=261&url=https%3A%2F%2Fthumbor.forbes.com%2Fthumbor%2Ffit-in%2F1200x0%2Ffilters%253Aformat%2528jpg%2529%2Fhttps%253A%252F%252Fspecials-images.forbesimg.com%252Fimageserve%252F1207963428%252F0x0.jpg&cfs=1&l&ext=jpg&_nc_hash=AQA43SmuawhqAExt',
      link:
        'https://www.forbes.com/sites/sandramacgregor/2020/07/01/canada-extends-coronavirus-travel-ban-on-most-foreign-travelers/#24ad713570a8',
    },
    {
      labels: ['Canada'],
      title:
        'CERB for international students: What you should know about the extension',
      description:
        'International students in Canada who were previously unable to apply for the CERB (Canada Emergency Response Benefit) can still do so. Justin Trudeau, Prime Minister of Canada ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDO6f0N-U-WaeI8&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2Fcerb-for-international-students-feat.jpg&cfs=1&ext=jpg&_nc_hash=AQDWU2ydVW8QKvu_',
      link:
        'https://www.studyinternational.com/news/cerb-for-international-students-what-you-should-know-about-the-extension/',
    },
  
    {
        labels: ['Canada'],
        title: 'Pandemic to redistribute international student flows: report',
        description:
          'The Anglosphere’s international education success stories of Canada, Australia and New Zealand can look forward to a foreign enrolment windfall as they brush ...',
      image: 'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDjk31tJZ3-RTTC&w=500&h=261&url=https%3A%2F%2Fwww.timeshighereducation.com%2Fsites%2Fdefault%2Ffiles%2Finternational_students_0.jpg&cfs=1&ext=jpg&_nc_hash=AQDuiamOPyULfS7a',
      link:
        'https://www.timeshighereducation.com/news/pandemic-redistribute-international-student-flows-report#',
    },
    {
      labels: ['Canada'],
      title: 'Coronavirus has increased interest in immigrating to Canada',
      description:
        'The economic impacts of coronavirus have largely not changed people’s plans to immigrate to Canada. In most cases, prospective immigrants still expect that Canada will endure less economic hardship than their own country. ...',
      image: '',
      link:
        'https://www.cicnews.com/2020/06/coronavirus-has-increased-interest-in-immigrating-to-canada-0614757.html#gs.9rcxn9',
    },
    {
      labels: ['Canada'],
      title:
        'COVID-19: Canada extends ban on international travellers to July 31',
      description:
        'Canada implemented a series of measures, including the ban on entry to all travellers who are not Canadian citizens and permanent residents, to stem the spread ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCwnBcNziKV4VvJ&w=500&h=261&url=https%3A%2F%2Fwww.cicnews.com%2Fwp-content%2Fuploads%2F2020%2F06%2F20200618eca-2.jpg&cfs=1&ext=jpg&_nc_hash=AQCZT-mYjIWm1js5',
      link:
        'https://www.newindianexpress.com/world/2020/jul/01/covid-19-canada-extends-ban-on-international-travellers-to-july-31-2163816.html',
    },
  
    //USA
    {
      labels: ['UK'],
      title:
        'If overseas students stay away, universities can enrol more disadvantaged people',
      description:
        'UK institutions are expecting a drop in international enrolments – so why not take on students with lower grades? In the UK, international students’ tuition fees are ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCI6FC9UtH6Utud&w=500&h=261&url=https%3A%2F%2Fi.guim.co.uk%2Fimg%2Fmedia%2F89e94071edfd9f4379caa5949867417323a10775%2F0_99_2121_1273%2Fmaster%2F2121.jpg%3Fwidth%3D1200%26height%3D630%26quality%3D85%26auto%3Dformat%26fit%3Dcrop%26overlay-align%3Dbottom%252Cleft%26overlay-width%3D100p%26overlay-base64%3DL2ltZy9zdGF0aWMvb3ZlcmxheXMvdGctb3BpbmlvbnMucG5n%26enable%3Dupscale%26s%3D150c10ce2d6e6838c935cf980cddad7f&cfs=1&ext=jpg&_nc_hash=AQCwWZNKtA4Bj0Gc',
      link:
        'https://www.theguardian.com/education/2020/jul/02/if-overseas-students-stay-away-universities-can-enrol-more-disadvantaged-people',
    },
  
    {
      labels: ['UK'],
      title:
        "Indian students' UK post-study work visa rights safe amid COVID-19 crisis",
      description:
        'The UK Home Office said the Graduate Visa route, commonly referred to as a post-study work visa and designed for overseas students to be able to work or look for work for two ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBOWTCLP3mpRNPw&w=500&h=261&url=https%3A%2F%2Fimg.etimg.com%2Fthumb%2Fmsid-76449587%2Cwidth-1070%2Cheight-580%2Cimgsize-386895%2Coverlay-economictimes%2Fphoto.jpg&cfs=1&ext=jpg&_nc_hash=AQD3L11QWpRZecvm',
      link:
        'https://economictimes.indiatimes.com/nri/visa-and-immigration/indian-students-uk-post-study-work-visa-rights-safe-amid-covid-19-crisis/articleshow/76449528.cms',
    },
  
    {
      labels: ['UK'],
      title:
        'Indian students on course for UK universities despite COVID-19 lockdown disruption',
      description:
        'The UCAS, the coordinator of a centralised application process for British universities, found a 32.9 per cent rise in applications from India this year over 2019, to hit a record 6,230.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCs_eWZaEFz8hn8&w=500&h=261&url=https%3A%2F%2Fimages.newindianexpress.com%2Fuploads%2Fuser%2Fimagelibrary%2F2020%2F6%2F25%2Fw600X390%2Funiversity-105709_1920.jpg&cfs=1&ext=jpg&_nc_hash=AQBJLKhWshEAbwdj',
      link:
        'https://www.newindianexpress.com/world/2020/jun/25/indian-students-on-course-for-uk-universities-despite-covid-19-lockdown-disruption-2161374.html',
    },
  
    {
      labels: ['UK'],
      title: "Indian students to benefit from UK's extended PhD post-study visa",
      description:
        'As part of a wider Research and Development Roadmap, the British government has pledged to make it easier for international graduates to secure skilled jobs in the UK ...',
      image: '',
      link:
        'https://www.freepressjournal.in/world/indian-students-to-benefit-from-uks-extended-phd-post-study-visa',
    },
  
    {
      labels: ['UK'],
      title:
        'Amid uproar over new security law, UK PM Boris Johnson offers 3 million Hong Kong residents a chance to get British Citizenship',
      description:
        "Condemning China's new security law, the British government on Wednesday said it would offer eligible Hong Kong residents a chance to settle in the United Kingdom  ...",
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQC1nhB1gFo8uuTI&w=500&h=261&url=https%3A%2F%2Fimages.assettype.com%2Ffreepressjournal%252F2020-06%252F72777394-7fc8-46b2-bfc6-8c0451521b22%252Fjohnson_web.jpg%3Fw%3D1200%26auto%3Dformat%252Ccompress%26ogImage%3Dtrue&cfs=1&ext=jpg&_nc_hash=AQCVOZTNOuMCHtrL',
      link:
        'https://www.freepressjournal.in/world/amid-uproar-over-new-security-law-uk-pm-boris-johnson-offers-3-million-hong-kong-residents-a-chance-to-get-british-citizenship',
    },
    {
      labels: ['UK'],
      title:
        'Graduate route: International students still eligible if they enter the UK before April 2021',
      description:
        'International graduates from UK universities don’t have to bid goodbye to their dreams of working in the UK yet. They can stay in the UK for up to two years after graduating in summer ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAUo27PnUGoNInx&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F07%2F000_1S31U2.jpg&cfs=1&ext=jpg&_nc_hash=AQCAQf29rDVVMTi7',
      link:
        'https://www.studyinternational.com/news/working-in-the-uk-international-students/',
    },
  
    {
      labels: ['UK'],
      title:
        'UK visa process: Indian students to benefit from UK’s extended PhD post-study visa',
      description:
        'British Council described the new announcements as "exciting news", which would help strengthen the talent and research pipeline between India and the UK ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDkS6TtjNIKzMtq&w=500&h=261&url=https%3A%2F%2Fimages.financialexpress.com%2F2020%2F05%2Fstudents-1.jpg&cfs=1&ext=jpg&_nc_hash=AQAUfAbCmg_wYGql',
      link:
        'https://www.financialexpress.com/passport-visa/uk-visa-process-indian-students-to-benefit-from-uks-extended-phd-post-study-visa/2010456/',
    },
    {
        labels: ['UK'],
        title:
          'UK promises post-study work visa for foreign students forced to start university course online',
        description:
          'While many top universities have turned flexible with student intake, and are lowering the selection criteria to increase the number of falling foreign students, the UK government is ...',
        image: '',
        link:
          'https://www.businessinsider.in/careers/news/uk-promises-post-study-work-visa-for-foreign-students-forced-to-start-university-course-online/articleshow/76542986.cms',
      },
  
    {
      labels: ['UK'],
      title: 'Three-year visas for PhD graduates in £300 million UK science plan',
      description:
        'Plans to invest £300 million in scientific infrastructure, extend post-study work visas for PhD graduates and establish an “Office for Talent” to attract top international researchers have ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDUl2C6cQTseZFn&w=500&h=261&url=https%3A%2F%2Fwww.timeshighereducation.com%2Fsites%2Fdefault%2Ffiles%2Fholding_map.jpg&cfs=1&ext=jpg&_nc_hash=AQAz1HCuKpV3fiGc',
      link:
        'https://www.timeshighereducation.com/news/three-year-visas-phd-graduates-ps300million-uk-science-plan',
    },
  
    {
      labels: ['UK'],
      title: 'Universities Minister sets out support for international students',
      description:
        'Measures to support international students and the higher education sector against the global challenges coronavirus poses were outlined by the Universities Minister today (5 June).',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDLXQAwav7hMQxE&w=500&h=261&url=https%3A%2F%2Fassets.publishing.service.gov.uk%2Fgovernment%2Fuploads%2Fsystem%2Fuploads%2Fimage_data%2Ffile%2F99987%2Fs960_lecture-theatre.jpg&cfs=1&ext=jpg&_nc_hash=AQAnEuG14hhyVW9n',
      link:
        'https://www.gov.uk/government/news/universities-minister-sets-out-support-for-international-students',
    },
  
    {
      labels: ['UK'],
      title:
        'UK promises post-study work visa for foreign students forced to start university course online',
      description:
        'While many top universities have turned flexible with student intake, and are lowering the selection criteria to increase the number of falling foreign students, the UK government is making it easier for its foreign students to study and find jobs in the country — amid the COVID crisis.',
      image: '',
      link:
        'https://www.businessinsider.in/careers/news/uk-promises-post-study-work-visa-for-foreign-students-forced-to-start-university-course-online/articleshow/76542986.cms',
    },
  
    {
      labels: ['UK'],
      title:
        'Post-Study Work Visa Guidelines Updated For International Students In UK',
      description:
        'The UK government has issued updated guidance to confirm that international students, including Indians, will remain eligible for post-study work rights at the end of their degree ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAFadI3Jo9r77l5&w=500&h=261&url=https%3A%2F%2Fcache.careers360.mobi%2Fmedia%2Farticle_images%2F2020%2F6%2F19%2Fstudy.jpeg&cfs=1&ext=jpg&_nc_hash=AQDldAqx4SuQDNkn',
      link:
        'https://www.ndtv.com/education/indian-students-uk-post-study-work-visa-rights-safe-amid-covid-19-crisis',
    },
  
    {
      labels: ['UK'],
      title:
        'Study Abroad: Indian students pursuing PhD in UK Universities allowed to work for 3 years after completion',
      description:
        'Indian students can benefit from the extended post-study work visa offer for PhD students announced recently by the Government of UK. It also guaranteed a  more streamlined visa process for international ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCGi9BeJ_xofCMs&w=500&h=261&url=https%3A%2F%2Fimgk.timesnownews.com%2Fstory%2Fhappy_students_1.jpg%3Ftr%3Dw-560%2Ch-292%2Cfo-top&cfs=1&ext=jpg&_nc_hash=AQBM83x7LGWJ2z-r',
      link:
        'https://www.timesnownews.com/education/article/study-abroad-indian-students-pursuing-phd-in-uk-universities-allowed-to-work-for-3-years-after-completion/615188',
    },
  
    {
      labels: ['UK'],
      title:
        'Amid uproar over new security law, UK PM Boris Johnson offers 3 million Hong Kong residents a chance to get British Citizenship',
      description:
        "Condemning China's new security law, the British government on Wednesday said it would offer eligible Hong Kong residents a chance to settle in the United Kingdom ...",
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQC1nhB1gFo8uuTI&w=500&h=261&url=https%3A%2F%2Fimages.assettype.com%2Ffreepressjournal%252F2020-06%252F72777394-7fc8-46b2-bfc6-8c0451521b22%252Fjohnson_web.jpg%3Fw%3D1200%26auto%3Dformat%252Ccompress%26ogImage%3Dtrue&cfs=1&ext=jpg&_nc_hash=AQCVOZTNOuMCHtrL',
      link:
        'https://www.freepressjournal.in/world/amid-uproar-over-new-security-law-uk-pm-boris-johnson-offers-3-million-hong-kong-residents-a-chance-to-get-british-citizenship',
    },
  
    {
      labels: ['UK'],
      title: 'Britain as ‘Science Superpower’',
      description:
        'Plans to invest 300 million pounds ($364 million) in scientific infrastructure, extend poststudy work visas for Ph.D. graduates and establish an Office for Talent to attract top ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?w=500&h=261&url=https%3A%2F%2Fwww.insidehighered.com%2Fsites%2Fdefault%2Fserver_files%2Fmedia%2FTHE%2520Logo%2520%25282016%2529_3_0_0.jpg&cfs=1&ext=jpg&_nc_hash=AQB7zgWkEyCSmsqL',
      link:
        'https://www.insidehighered.com/news/2020/07/02/britain-launches-plan-become-science-superpower',
    },
  
    {
      labels: ['UK'],
      title: 'UK to process visas from Chennai applicants from July 6',
      description:
        'CHENNAI: With international flights set to start, the UK and Ireland will start processing visas from applicants in Chennai from July 6. While people can apply for all ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAbiKdXqH11Zun9&w=500&h=261&url=https%3A%2F%2Fstatic.toiimg.com%2Fthumb%2Fmsid-76742314%2Cwidth-1070%2Cheight-580%2Cimgsize-220028%2Cresizemode-75%2Coverlay-toi_sw%2Cpt-32%2Cy_pad-40%2Fphoto.jpg&cfs=1&ext=jpg&_nc_hash=AQDsigv8mBRJzzCl',
      link:
        'https://timesofindia.indiatimes.com/city/chennai/uk-to-process-visas-from-chennai-applicants-from-july-6/articleshow/76742293.cms',
    },
  
    // Germany
    {
      labels: ['Germany'],
      title: 'Information on the Corona virus - as of 02/07/2020',
      description:
        'The DAAD continues to follow the developments on the coronavirus and adapts its activities flexibly to changing situations.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBUknve44zEYXD6&w=500&h=261&url=https%3A%2F%2Fapi.daad.de%2Fapi%2Fimage%2Fog%2Fmedia%2Fdaad_de%2Fstartseite%2Fistock-1136191676_1000px.jpg&cfs=1&ext=jpg&_nc_hash=AQCfIMGSKqFV6E15',
      link:
        'https://www.daad.de/en/coronavirus/#Information%20for%20international%20scholarship%20holders%20and%20applicants',
    },
    {
      labels: ['Germany'],
      title:
        'Germany: success of international students at heart of 2025 strategy',
      description:
        'The academic success of international students will be level with the average success of domestic students in Germany if new targets German Academic Exchange Service (DAAD) ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBQtn7H0SS3i9m6&w=500&h=261&url=https%3A%2F%2Fd1pe6f90ru47yo.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2F22181252%2Fhamburg-3813578_1920-860x375.jpg&cfs=1&ext=jpg&_nc_hash=AQCL63j_McOVvSPY',
      link:
        'https://thepienews.com/news/germany-success-intl-students-at-heart-of-daad-2025-strategy/',
    },
    {
      labels: ['Germany'],
      title:
        'Coronavirus: Germany could benefit as foreign students ditch US, UK',
      description:
        "Western universities are expecting a massive drop in international student enrollment due to the pandemic. Germany's zero tuition fees, top health care system and coronavirus ...",
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBR92KkzDDbFL3T&w=500&h=261&url=https%3A%2F%2Fwww.dw.com%2Fimage%2F19034454_304.jpg&cfs=1&ext=jpg&_nc_hash=AQC39ah85P0v6frf',
      link:
        'https://www.dw.com/en/coronavirus-germany-could-benefit-as-foreign-students-ditch-us-uk/a-54025218',
    },
    {
      labels: ['Germany'],
      title:
        'Which countries are on the list of those now permitted to enter Germany?',
      description:
        "Europe's borders have now opened up, but only to travellers from certain countries - here is the latest on the German travel rules for people from outside Europe ...",
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBXfOcNQccdqRTg&w=500&h=261&url=https%3A%2F%2Fwww.thelocal.de%2Fuserdata%2Fimages%2Farticle%2Fefa556f4a86aee46ccbbef5f50ba67ba8c86e3d2ebbd4070a3b2b79b26411cc5.jpg&cfs=1&ext=jpg&_nc_hash=AQBogPL_Q_PHgw7G',
      link:
        'https://www.thelocal.de/20200702/which-countries-are-on-the-list-of-those-now-permitted-to-enter-germany',
    },
  
    // France
    {
      labels: ['France'],
      title: 'International students can enter France from July 1',
      description:
        'Planning to study in France this year? Good news — France is planning to reopen its borders to international students as early as July 1 this year. In a tweet, the French Ministry ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQA5OKNQsV0xdsLs&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2Ffrance-borders-feature.jpg&cfs=1&ext=jpg&_nc_hash=AQDElZci2LsyInmF',
      link:
        'https://www.studyinternational.com/news/france-borders-visas-july-1-2020/',
    },
    {
      labels: ['France'],
      title: 'France to allow int’l students in from July',
      description:
        'France has announced that international students will be allowed in the country from July 1, whatever their country of origin, and that their visa and residence permit requests ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDJD9ZIgD8aX0EX&w=500&h=261&url=https%3A%2F%2Fd1pe6f90ru47yo.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2F15172746%2Ftourist-in-paris-3596709-860x375.jpg&cfs=1&ext=jpg&_nc_hash=AQC5VmIOZaqBMYph',
      link: 'https://thepienews.com/news/france-exempt-students-restrictions/',
    },
    {
      labels: ['France'],
      title: 'International Students Can Enter France as of July 1',
      description:
        'The French Ministry for Europe and Foreign Affairs has invited international students who need to travel to France for study purposes to do so starting from July 1, ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAyfCxAgsCaUGCr&w=500&h=261&url=https%3A%2F%2Fwww.schengenvisainfo.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2FSorbonne-University-in-Paris-France.jpg&cfs=1&ext=jpg&_nc_hash=AQDe1a6d_Iqae5Tl',
      link:
        'https://www.schengenvisainfo.com/news/international-students-can-enter-france-as-of-july-1/',
    },
  
    // Ireland
    {
      labels: ['Ireland', 'Australia'],
      title: 'Universities consider charter flights for international students',
      description:
        'Universities around the world are planning on chartering planes for international students to bring them onto campuses, as part of a bid to tackle travel disruption caused by Covid-19 ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQACTBJPJQhOBcov&w=500&h=261&url=https%3A%2F%2Fd1pe6f90ru47yo.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2F25144220%2Fpexels-photo-1309644-860x375.jpg&cfs=1&ext=jpg&_nc_hash=AQDMfT7xRQ5n19RZ',
      link:
        'https://thepienews.com/news/unis-consider-charter-flights-for-international-students/',
    },
    {
      labels: ['Ireland'],
      title:
        'COVID-19: Visa application centres reopen in India for select countries',
      description:
        'Out of the 64 client governments that VFS Global works for in 144 countries at 3,390 application centres, it has already resumed operations 632 centres in 80 countries ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAay1OkO30N_A-T&w=500&h=261&url=https%3A%2F%2Fimages.newindianexpress.com%2Fuploads%2Fuser%2Fimagelibrary%2F2020%2F7%2F2%2Fw600X390%2FVisa.jpg&cfs=1&ext=jpg&_nc_hash=AQAY-ou95i5bGzLB',
      link:
        'https://www.newindianexpress.com/nation/2020/jul/02/covid-19-visa-application-centres-reopen-in-india-for-select-countries-2164368.html',
    },
  
    // Australia
    {
      labels: ['Australia'],
      title:
        'Australia considering shorter Covid-19 quarantine for students and low-transmission countries',
      description:
        'Senior Morrison government ministers have flagged shorter quarantine periods for international students and business travellers as part of a suite of measures to reopen ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBOvAXwIRGzrMrY&w=500&h=261&url=https%3A%2F%2Fi.guim.co.uk%2Fimg%2Fmedia%2F694dbe9ad3cfa125613c229a297e96ed8d437e91%2F0_265_4520_2713%2Fmaster%2F4520.jpg%3Fwidth%3D1200%26height%3D630%26quality%3D85%26auto%3Dformat%26fit%3Dcrop%26overlay-align%3Dbottom%252Cleft%26overlay-width%3D100p%26overlay-base64%3DL2ltZy9zdGF0aWMvb3ZlcmxheXMvdGctZGVmYXVsdC5wbmc%26enable%3Dupscale%26s%3D8844dfa54f92205b768748134797e95a&cfs=1&ext=jpg&_nc_hash=AQDif0pru1hvenY2',
      link:
        'https://www.theguardian.com/australia-news/2020/jun/14/australia-considering-shorter-covid-19-quarantine-for-students-and-low-transmission-countries',
    },
    {
      labels: ['Australia'],
      title: 'StudyPerth Int’l Student Meals Program launched',
      description:
        'Perth’s international students have come together to support one another through a new student chef food program for those in need due to the Covid-19 crisis. StudyPerth has ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAA2eheREWPEvTz&w=500&h=261&url=https%3A%2F%2Fd1pe6f90ru47yo.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2F09130633%2Fhappy-woman-preparing-dough-at-home-3770002-scaled-e1591708094787-860x375.jpg&cfs=1&ext=jpg&_nc_hash=AQC0XdqSeMVRYaKh',
      link:
        'https://thepienews.com/news/studyperth-intl-student-meals-program-launched/',
    },
    {
      labels: ['Australia'],
      title: 'Australia blasts China for creating ‘disinformation’ in pandemic',
      description:
        'Canberra: Australian Foreign Minister Marise Payne criticized China for creating “disinformation” during the coronavirus pandemic, saying it “will cost lives.”',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBHP2eAZ-qf9kmQ&w=500&h=261&url=https%3A%2F%2Fd2c7ipcroan06u.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2FMARISE-PAYNE-1-e1592317217497.jpg&cfs=1&ext=jpg&_nc_hash=AQC9ZjtOWCSDUC4b',
      link:
        'https://theprint.in/world/australia-blasts-china-for-creating-disinformation-in-pandemic/442848/',
    },
    {
      labels: ['Australia'],
      title: 'Australia to open borders for one group: International students',
      description:
        'Australia’s higher education sector has become increasingly reliant on overseas students with 40% of revenue coming from the higher fees they are charged.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBO6MXSFIBx7o5q&w=500&h=261&url=https%3A%2F%2Fd2c7ipcroan06u.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F07%2F363044416-scaled-e1593662542769.jpg&cfs=1&ext=jpg&_nc_hash=AQAZXih92trI6fj2',
      link:
        'https://theprint.in/world/australia-to-open-borders-for-one-group-international-students/452829/',
    },
    {
      labels: ['Australia'],
      title:
        'Australian Prime Minister expects foreign students to return in July',
      description:
        'International students are expected to begin returning to Australia next month despite Chinese warnings of pandemic-related racism, the Australian Prime Minister said on Friday.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCEYb_2J5-BB3yj&w=500&h=261&url=https%3A%2F%2Fwww.thehindu.com%2Fnews%2Finternational%2Fn9loeb%2Farticle31813627.ece%2FALTERNATES%2FLANDSCAPE_615%2FSCOTTKB&cfs=1&ext=jpg&_nc_hash=AQBHcis0qRzZL6xk',
      link:
        'https://www.thehindu.com/news/international/australian-prime-minister-expects-foreign-students-to-return-in-july/article31813629.ece',
    },
    {
      labels: ['Australia'],
      title: 'Why US College ‘Fall Plans’ Don’t Work for International Students',
      description:
        'With coronavirus came the disruption of education as schools and colleges across the world quickly adapted to the online learning format. As colleges across the United States ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBc0hDURGQDQsM3&w=500&h=261&url=https%3A%2F%2Fimages.thequint.com%2Fthequint%252F2020-07%252Ff1c8d3bd-e49e-49fe-ac37-15fa396cd174%252Fpandemic.jpg%3Frect%3D0%252C0%252C1799%252C944%26w%3D1200%26auto%3Dformat%252Ccompress%26ogImage%3Dtrue&cfs=1&ext=jpg&_nc_hash=AQCCgAKDHJBEUz-3',
      link:
        'https://www.thequint.com/voices/blogs/why-us-college-fall-plans-dont-work-for-international-students',
    },
    {
      labels: ['Australia'],
      title: 'What does Australia look like without international students?',
      description:
        'Discussion of education during the pandemic has focused almost solely on losses of funding at universities, but the economic and social role of international students ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQAeS04b4DCl89_q&w=500&h=261&url=https%3A%2F%2Fwww.policyforum.net%2Fwp-content%2Fuploads%2F2020%2F06%2Fkimberly-farmer-lUaaKCUANVI-unsplash-1024x681.jpg&cfs=1&ext=jpg&_nc_hash=AQDYcyXGPutRC3rr',
      link:
        'https://www.policyforum.net/what-does-australia-look-like-without-international-students/',
    },
  
    // NZ
    {
      labels: ['New Zealand'],
      title: 'New Zealand: Int’l students unlikely to return in 2020',
      description:
        'International students are unlikely to return to New Zealand this year, according to the country’s finance minister Grant Robertson. However, it is expected that the government ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?w=500&h=261&url=https%3A%2F%2Fd1pe6f90ru47yo.cloudfront.net%2Fwp-content%2Fuploads%2F2020%2F06%2F30143813%2Fmatt-lamers-bRFwsLti6YI-unsplash-860x375.jpg&cfs=1&ext=jpg&_nc_hash=AQAJVHVXnSjd-ykN',
      link:
        'https://thepienews.com/news/new-zealand-international-students-unlikely-to-return-in-2020/',
    },
    {
      labels: ['New Zealand'],
      title:
        "Coronavirus: International students 'much more likely' to return to New Zealand in 2021 than this year - Grant Robertson",
      description:
        'Foreign students are "much more likely" to return to New Zealand universities in 2021 than at any point this year thanks to COVID-19, the Finance Minister has admitted. ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDBRYY8joT-QXDT&w=500&h=261&url=https%3A%2F%2Fwww.newshub.co.nz%2Fhome%2Fnew-zealand%2F2020%2F07%2Fcoronavirus-international-students-much-more-likely-to-return-to-new-zealand-in-2021-than-this-year-grant-robertson%2F_jcr_content%2Fpar%2Fvideo%2Fimage.dynimg.1280.q75.jpg%2Fv1593666694103%2Fv2-Grant-Robertson-110620-Newshub-1120.jpg&cfs=1&ext=jpg&_nc_hash=AQA0IoId3DJkeeoC',
      link:
        'https://www.newshub.co.nz/home/new-zealand/2020/07/coronavirus-international-students-much-more-likely-to-return-to-new-zealand-in-2021-than-this-year-grant-robertson.html',
    },
    {
      labels: ['New Zealand'],
      title:
        'Tests and quarantine: Is this how New Zealand will let international students return?',
      description:
        'COVID-19 screenings at departure and arrival, two COVID-19 tests, 14 days of quarantine and no work rights while studying — these are the requirements being proposed by the ...',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCobNdSJy8yHpMx&w=500&h=261&url=https%3A%2F%2Fcdn.studyinternational.com%2Fnews%2Fwp-content%2Fuploads%2F2020%2F06%2F000_1T24SW.jpg&cfs=1&ext=jpg&_nc_hash=AQAdP1lOuCBNpW9I',
      link:
        'https://www.studyinternational.com/news/international-students-new-zealand-2020-july/',
    },
    {
      labels: ['New Zealand'],
      title:
        'The Detail: Pandemic jeopardising the future of international students',
      description:
        "It's our fifth-biggest export earner, contributing more than $5 billion each year to the economy – but the impact of Covid-19 means the international student cash cow ...",
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQC5SWioV1aOGEqY&w=500&h=261&url=https%3A%2F%2Fresources.stuff.co.nz%2Fcontent%2Fdam%2Fimages%2F4%2Fy%2Fm%2Fi%2Fw%2F9%2Fimage.related.StuffLandscapeSixteenByNine.1420x800.4yn1ow.png%2F1593601846134.jpg&cfs=1&ext=jpg&_nc_hash=AQCxhNgDQ2EfR5dv',
      link:
        'https://www.stuff.co.nz/national/the-detail/300046928/the-detail-pandemic-jeopardising-the-future-of-international-students',
    },
    {
      labels: ['New Zealand'],
      title: 'Border reopening must be priority - Business NZ',
      description:
        'Business leaders say billions of dollars of opportunities are on hold while the government and the army fix up mistakes most New Zealanders thought were being managed.',
      image:
        'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQDLxPfzu2nFp4sS&w=500&h=261&url=https%3A%2F%2Fwww.rnz.co.nz%2Fassets%2Fnews_crops%2F30190%2Feight_col_28422435_xxl.jpg%3F1490056972&cfs=1&ext=jpg&_nc_hash=AQB5zwDEl-ZyIBI-',
      link:
        'https://www.rnz.co.nz/news/political/420127/border-reopening-must-be-priority-business-nz',
    },
    {
      labels: ['Hong Kong and Singapore'],
      title: 'Students shocked as prestigious art college SCAD closes Hong Kong campus',
      description: 'Just a ten-minute walk from the buzzing flea market and working-class neighbourhood in Sham Shui Po, there stands the Hong Kong campus of the US private art school Savannah College of Art and Design (SCAD).',
      image: 'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQB1AgBUTy57mhdM&w=500&h=261&url=https%3A%2F%2Fi2.wp.com%2Fhongkongfp.com%2Fwp-content%2Fuploads%2F2020%2F06%2FSCAD-HK-2.jpg%3Ffit%3D1200%252C675&cfs=1&ext=jpg&_nc_hash=AQB0p41I2OZJa14X',
      link:
        'https://hongkongfp.com/2020/06/28/students-shocked-as-prestigious-art-college-scad-closes-hong-kong-campus/',
    },
    {
      labels: ['Hong Kong and Singapore'],
      title: 'Hong Kong universities rattled by new security law',
      description: 'Academics in Hong Kong are facing new worries today after the mainland government yesterday abruptly imposed a controversial new security law that critics say could infringe on human rights and chill academic freedom.ws',
      image: 'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQCUV_OA5IlahPzF&w=500&h=261&url=https%3A%2F%2Fwww.sciencemag.org%2Fsites%2Fdefault%2Ffiles%2Fstyles%2Farticle_main_large%2Fpublic%2FHongKong-1280p.jpg%3Fitok%3Dt74mI9_Y&cfs=1&ext=jpg&_nc_hash=AQBFggIe9CmkB7Oe',
      link:
        'https://www.sciencemag.org/news/2020/07/hong-kong-universities-rattled-new-security-law',
    },
    {
      labels: ['Hong Kong and Singapore'],
      title: 'COVID-19: Singaporeans enrolled in overseas universities unsure about future studies',
      description: 'Student Carolyn Tan was on the bus to the airport to board a plane back to Singapore when she received an email from her university informing students that it would be closing all its facilities and moving classes online in response to the COVID-19 pandemic.',
      image: 'https://external-bom1-1.xx.fbcdn.net/safe_image.php?d=AQBH1gbOlVRaL7EC&w=500&h=261&url=https%3A%2F%2Fcna-sg-res.cloudinary.com%2Fimage%2Fupload%2Fq_auto%2Cf_auto%2Fimage%2F12753992%2F16x9%2F991%2F557%2F47287b07ec00d02a2e2db49aecb7bb78%2FNn%2Ffile-photo--the-spread-of-the-coronavirus-disease--covid-19--in-cambridge-1.jpg&cfs=1&ext=jpg&_nc_hash=AQAwm25Z2hNI7X0k',
      link:
        'https://www.channelnewsasia.com/news/singapore/covid-19-singaporean-students-overseas-universities-12826694',
    },
    {
      labels: ['Hong Kong and Singapore'],
      title: 'Asia Minute: Coronavirus Takes a Toll on International Students',
      description: 'One of many questions about the phased re-openings of communities around the world concerns education. It’s not just about the plans for local schools, there are still a lot of issues concerning international students. And that includes in the Asia Pacific.',
      image: null,
      link:
        'https://www.hawaiipublicradio.org/post/asia-minute-coronavirus-takes-toll-international-students#stream/0',
    },
  ];

export const DEFAULT_FEED_IMG = 'https://external.fdel1-3.fna.fbcdn.net/safe_image.php?d=AQBNZ3zPbFyO0BLc&w=500&h=261&url=https%3A%2F%2Fimages.indianexpress.com%2F2020%2F03%2Fstudyabroad759.jpg%3Fw%3D759&cfs=1&ext=jpg&_nc_hash=AQCiSugFRjayVH6o'