import React from 'react';

import CountrySelectorDropDown from '@components/common/Generic/CountrySelectorDropDown'
import CountryTabs from '@components/common/Generic/CountryTabs'

const CountrySelector = ({ selectedIndex, onChange, countries }) => {
    return <div>
        <CountryTabs selectedIndex={selectedIndex} onChange={onChange} countries={countries} />
        <hr style={{width: '60%', marginLeft: 0}} />
        <CountrySelectorDropDown onChange={onChange} countries={countries} />
    </div>
}

export default CountrySelector